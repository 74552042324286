@font-face {
  font-family: 'NotoSansKR';
  src: url('../fonts/NotoSansKR-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('../fonts/NotoSansKR-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('../fonts/NotoSansKR-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('../fonts/NotoSansKR-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'wadizicon';
  font-weight: 400;
  font-style: normal;
  src: url(https://static.wadiz.kr/studio/funding/static/media/wadizicon.63f94578.eot#iefix)
      format('embedded-opentype'),
    url(https://static.wadiz.kr/studio/funding/static/media/wadizicon.a5ebdfe2.woff2)
      format('woff2'),
    url(https://static.wadiz.kr/studio/funding/static/media/wadizicon.3a85d863.woff)
      format('woff'),
    url(https://static.wadiz.kr/studio/funding/static/media/wadizicon.ac5f1633.ttf)
      format('truetype'),
    url(https://static.wadiz.kr/studio/funding/static/media/wadizicon.b948de66.svg#wadizicon)
      format('svg');
}
