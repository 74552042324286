.fr-view {
  body,
  button,
  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  html,
  input,
  li,
  ol,
  p,
  span,
  textarea,
  ul {
    -webkit-appearance: none;
    margin: 0;
    border: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
  }
}

#darkEditor {
  .fr-view {
    background-color: black;
  }
}

.fr-view {
  word-break: keep-all;
  color: #343a40;
  overflow-wrap: break-word;
  background-size: 30%;
  font-family: NotoSansKR;
  width: 100%;
}
.fr-view h1 {
  line-height: 62px;
  letter-spacing: -0.6px;
  font-size: 48px;
  font-weight: bold;
  font-style: normal;
  margin-top: 48px;
  margin-bottom: 16px;
  color: #212529;
}
.fr-view h2 {
  line-height: 48px;
  letter-spacing: -0.6px;
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  margin-top: 48px;
  margin-bottom: 16px;
  color: #212529;
}
.fr-view h3 {
  line-height: 38px;
  letter-spacing: -0.6px;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  margin-top: 40px;
  margin-bottom: 16px;
  color: #212529;
}
.fr-view h4 {
  line-height: 30px;
  letter-spacing: -0.6px;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  margin-top: 32px;
  margin-bottom: 16px;
  color: #212529;
}
.fr-view h5 {
  line-height: 20px;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
  color: #adb5bd;
}
.fr-view p {
  line-height: 28px;
  letter-spacing: -0.3px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #495057;
}
.fr-view u {
  border-bottom: 1px solid #00c4c4;
  text-decoration: none;
}
.fr-view a {
  transition: color 0.3s;
  color: #557cf2;
}
.fr-view a:hover {
  text-decoration: underline;
  color: #395bbf;
}
.fr-view b,
.fr-view strong {
  font-weight: bold !important;
}
.fr-view hr {
  margin: 40px auto;
  border: 1px solid #e9ecef;
  width: 240px;
  text-align: center;
}
.fr-view ol {
  list-style: decimal;
}
.fr-view ol > li {
  line-height: 28px;
  letter-spacing: -0.3px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  position: relative;
  margin-left: calc(4px * 5);
  list-style: inherit;
}
.fr-view ul {
  list-style: disc;
}
.fr-view ul > li {
  line-height: 28px;
  letter-spacing: -0.3px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  margin-left: calc(4px * 5);
  list-style: inherit;
}
.fr-view ul > li > ul {
  list-style: circle;
}
.fr-view ul > li > ul > li > ul {
  list-style: circle;
}
.fr-view img {
  display: block;
  margin: auto;
  max-width: 100%;
  height: auto;
}
.fr-view iframe {
  display: block;
  margin: auto;
  max-width: 100%;
}
.fr-view table {
  margin: 24px 0;
  border: none;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  empty-cells: show;
  color: #495057;
}
.fr-view table thead th {
  background-color: #e9ecef;
  font-weight: bold;
}
.fr-view table tbody > tr {
  background-color: #f9fafb;
}
.fr-view table tbody > tr:nth-child(odd) {
  background-color: #fff;
}
.fr-view table th,
.fr-view table td {
  line-height: 20px;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  border: 1px solid #cdd3d8;
  padding: 4px 8px;
}
.fr-view table th img,
.fr-view table td img {
  margin: auto;
  padding: 0;
  width: auto !important;
  min-width: auto !important;
  height: auto !important;
}
.fr-view table th:empty,
.fr-view table td:empty {
  height: 24px;
}
.fr-view table td.head-cell {
  font-weight: bold;
}
.fr-view table td.fr-highlighted,
.fr-view table td th.fr-highlighted {
  border: 1px double #f66;
}
.fr-view table td.fr-thick,
.fr-view table td th.fr-thick {
  border-width: 2px;
}
.fr-view table.fr-dashed-borders th,
.fr-view table.fr-dashed-borders td {
  border-style: dashed;
  padding: 4px 12px;
}
.fr-view blockquote {
  color: #495057;
}
.fr-view blockquote.type-default {
  margin: 0 auto;
  border-left: 0;
  padding-left: 0;
  width: 100%;
  max-width: 480px;
}
.fr-view blockquote.type-default::before {
  display: block;
  margin: 40px auto 12px;
  width: 24px;
  height: 24px;
  line-height: 1;
  color: #adb5bd;
  font-family: wadizicon;
  font-size: 24px;
  content: '\E9F1';
}
.fr-view blockquote.type-default::after {
  display: block;
  margin: 12px auto 40px;
  width: 24px;
  height: 24px;
  line-height: 1;
  color: #adb5bd;
  font-family: wadizicon;
  font-size: 24px;
  content: '\E9F0';
}
.fr-view blockquote.type-default p {
  line-height: 34px;
  letter-spacing: -0.51px;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  color: #495057;
  font-weight: normal;
}
.fr-view blockquote.type-line {
  margin: 12px 0;
  border-left: 2px solid #868e96;
  padding-left: 16px;
  color: #495057;
}
.fr-view blockquote.type-line p {
  color: #495057;
  font-weight: bold;
}
.fr-view blockquote.type-line p:first-child {
  margin-top: 0;
}
.fr-view blockquote.type-line p:last-child {
  margin-bottom: 0;
}
.fr-view .fr-video {
  display: block;
  position: relative;
  margin: 32px 0;
  padding-top: 60%;
  width: 100%;
  height: 0;
  overflow: hidden;
}
.fr-view .fr-video iframe,
.fr-view .fr-video object,
.fr-view .fr-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
